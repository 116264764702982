import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Container, Stack, TextField, Typography } from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import DoneIcon from '@mui/icons-material/Done';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import Sidenav from "../../components/Sidenav";
import { ApiService } from "../../services/api.service";
import { PendingActions } from "@mui/icons-material";

const apiService = new ApiService();

interface ApplicationData {
  formName: string;
  sections: Section[];
}

interface Section {
  uuid: string;
  name: string;
  isHidden: boolean;
  questions: Question[];
}

interface Question {
  id: number;
  name: string;
  question: string;
  questionType: string;
  isRequired: boolean;
  hasConditionalSection: boolean;
  answers: Answer[];
  userAnswers: UserAnswer[];
}

interface Answer {
  answer: string;
  associatedSection: string;
}

interface UserAnswer {
  id: number;
  answer: string;
}

const Application = () => {
  const { applicationId } = useParams<{applicationId: string}>();

  const [application, setApplication] = useState<ApplicationData | null>(null);
  const [visibleSections, setVisibleSections] = useState<{[key: string]: boolean}>({});
  const [currentSectionIndex, setCurrentSectionIndex] = useState<number>(0);
  const [isSectionVisible, setIsSectionVisible] = useState(false);
  const [answeredQuestions, setAnsweredQuestions] = useState<number>(0);
  const [totalQuestions, setTotalQuestions] = useState<number>(0);
  const progressPercentage = totalQuestions > 0 ? (answeredQuestions / totalQuestions) * 100 : 0;

  const navigate = useNavigate();

  const getApplication = async () => {
    try {
      const response = await apiService.get('/applications/' + applicationId);
      setApplication(response?.data);

      console.log('Application');
      console.log(application);

      const visibility: {[key: string]: boolean} = {};

      response?.data.sections.forEach((section: Section) => {
        visibility[section.uuid] = !section.isHidden;
      });
      
      setVisibleSections(visibility);

      console.log('Visibility');
      console.log(visibility);

      let total = 0;
      let answered = 0;

      response?.data.sections.forEach((section: Section) => {
        if (!section.isHidden) {
          section.questions.forEach((question: Question) => {
            total += 1;
            
            if (question.userAnswers.length > 0 && question.userAnswers[0].answer) {
              answered += 1;
            }
          });
        }
      });

      setTotalQuestions(total);
      setAnsweredQuestions(answered);
    } catch (error) {
      console.error("Error getting application data:", error);
    }
  }

  useEffect(() => {
    getApplication();
  }, [applicationId]);

  const handleInputChange = (sectionUuid: string, questionId: number, value: string) => {
    if (!application) {
      return;
    }

    const updatedApplication = {
      ...application,
      sections: application.sections.map(section =>
        section.uuid === sectionUuid
        ? {
          ...section,
          questions: section.questions.map(question =>
            question.id === questionId
            ? {
              ...question,
              userAnswers: [
                ...question.userAnswers.map((answer, index) =>
                  index === 0
                    ? { ...answer, answer: value }
                    : answer
                )
              ]
            }
            : question
          )
        }
        : section
      )
    };

    setApplication(updatedApplication);

    let answered = 0;

    application.sections.forEach((section) => {
      if (!section.isHidden) {
        section.questions.forEach((question) => {
          if ((question.userAnswers.length > 0 && question.userAnswers[0].answer) || value.length > 0) {
            answered += 1;
          }
        });
      }
    });
    
    setAnsweredQuestions(answered);
  };

  const handleAnswerChange = (sectionUuid: string, questionId: number, answer: string) => {
    if (!application) return;

    const updatedApplication = { ...application };
    const section = updatedApplication.sections.find(s => s.uuid === sectionUuid);
    
    if (section) {
      const question = section.questions.find(q => q.id === questionId);
      
      if (question) {
        question.userAnswers = [{ id: 0, answer }];
      }
    }

    const newVisibleSections = { ...visibleSections };
    
    if (section) {
      section.questions.forEach(question => {
        question.answers.forEach(ans => {
          if (ans.associatedSection) {
            newVisibleSections[ans.associatedSection] = (ans.answer === answer);
          }
        });
      });
    }

    setApplication(updatedApplication);
    setVisibleSections(newVisibleSections);

    let answered = 0;
    
    application.sections.forEach((section) => {
      section.questions.forEach((question) => {
        if (question.userAnswers.length > 0 && question.userAnswers[0].answer) {
          answered += 1;
        }
      });
    });
    
    setAnsweredQuestions(answered);
  };

  const saveAnswers = async () => {
    const body = {
      userAnswers: application?.sections.flatMap(section =>
        section.questions.map(question => ({
          questionId: question.id,
          userAnswers: question.userAnswers
        }))
      )
    };

    try {
      await apiService.patch("/applications/" + applicationId, body).then((data) => {
        if (data) {
          navigate("/applications");
        }
      });
    } catch (error) {
      console.error("Error saving data:", error);
    }
  }

  const handleNext = () => {
    if (!application) {
      return;
    }

    let nextIndex = currentSectionIndex;
  
    do {
      nextIndex += 1;
    } while (nextIndex < application.sections.length && !visibleSections[application.sections[nextIndex].uuid]);
  
    if (nextIndex < application.sections.length) {
      setCurrentSectionIndex(nextIndex);
    }
  }
  
  const handleBack = () => {
    if (!application) {
      return;
    }
  
    let prevIndex = currentSectionIndex;
  
    do {
      prevIndex -= 1;
    } while (prevIndex >= 0 && !visibleSections[application.sections[prevIndex].uuid]);
  
    if (prevIndex >= 0) {
      setCurrentSectionIndex(prevIndex);
    }
  }

  const toggleSectionVisibility = () => {
    setIsSectionVisible(!isSectionVisible);
  };
  
  const currentSection = application?.sections[currentSectionIndex];

  console.log('Current section');
  console.log(currentSection);

  const style = {
    borderLeft: '4px solid #003D6E',
    borderRadius: '3px',
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)',
    marginBottom: '20px',
    padding: '30px'
  }

  const formHeaderStyle = {
    backgroundColor: '#FFF',
    borderTop: '6px solid #003D6E',
    borderRadius: '3px',
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)',
    marginBottom: '20px',
    padding: '30px'
  }

  const boxStyle = {
    display: 'flex',
  }

  const alignLeftStyle = {
    flex: '1'
  }

  const textAreaStyle = {
    width: '70%',
    padding: '0.375rem 1.75rem 0.375rem 0.75rem',
    fontSize: '1.2rem',
    fontFamily: 'Roboto',
    borderRadius: '3px'
  }

  const titleStyle = {
    width: '70%',
    marginBottom: '25px'
  }

  const inputStyle = {
    width: '70%',
    marginBottom: '15px'
  }

  const inputTitleStyle = {
    width: '70%',
    marginBottom: '45px'
  }

  const labelStyle = {
    marginLeft: '5px',
    marginBottom: '5px'
  }

  const optionsStyle = {
    marginBottom: '15px'
  }

  const pageStyle = {
    width: '70%',
    paddingTop: '5px',
    marginLeft: '50px'
  }

  const pendingQuestions = {
    display: isSectionVisible ? 'block' : 'none',
    color: 'red',
    padding: '15px',
    fontSize: '15px',
    backgroundColor: '#FFF',
    borderTop: '6px solid #003D6E',
    borderRadius: '3px',
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)',
    marginBottom: '20px'
  }

  const pendingQuestionsItems = {
    padding: '0px 0px 10px 10px'
  }

  const progressContainer = {
    width: '60%',
    height: '15px',
    backgroundColor: '#FFF',
    borderRadius: '5px',
    overflow: 'hidden'
  }
  
  const progressBar = {
    width: '80%',
    height: '100%',
    backgroundColor: '#4caf50',
    transition: 'width 0.3s ease'
  }
 
  const textFieldStyle = {
    marginBottom: '15px'
  }

  return (
    <>
      <Sidenav />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 10,
          bgcolor: '#F0F1F5'
        }}
      >
        <Container maxWidth="xl">          
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Stack spacing={1}>
              <Typography variant="h4">
                Application
              </Typography>
              <Stack 
                alignItems="center" 
                direction="row" 
                spacing={1}
              >
              </Stack>
            </Stack>
            <div>
              <Button 
                onClick={toggleSectionVisibility} 
                startIcon={<PendingActions />} 
                variant="contained"
                sx={{
                  mt: 2, 
                  marginBottom: '15px',
                  backgroundColor: '#003D6E',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#005F8C',
                  },
                }}
              >
                { isSectionVisible ? 'Hide Pending answers' : 'Show Pending answers' }
              </Button>
              <Button 
                onClick={() => {navigate("/applications")}} 
                startIcon={<ArrowLeftIcon />} 
                variant="contained"
                sx={{
                  mt: 2, 
                  marginBottom: '15px',
                  marginLeft: '10px',
                  backgroundColor: '#003D6E',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#005F8C',
                  },
                }}
              >
                Back
              </Button>
            </div>
          </Stack>
          <Stack style={pendingQuestions}>
          <Typography style={titleStyle}>Pending answers</Typography>
            {
              application?.sections.map((section) => {
                if (!section.isHidden) {
                  const unansweredQuestions = section.questions.filter((question) => question.userAnswers.length === 0);
                  
                  if (unansweredQuestions.length > 0) {
                    return (
                      <div key={section.uuid}>
                        <Typography style={{ fontSize: '16px', padding: '0px 0px 10px 0px', fontWeight: 'bold' }}>
                          {section.name}
                        </Typography>
                        {
                          unansweredQuestions.map((question) => (
                            <div key={question.id} style={pendingQuestionsItems}>
                              {question.question}
                            </div>
                          ))
                        }
                      </div>
                    );
                  }
                }

                return null;
              })
            }
          </Stack>
          {
            //application?.sections.map((section, index) => (visibleSections[section.uuid] && 
            currentSection && visibleSections[currentSection.uuid] && (
              <div key={currentSection.uuid}>
                <Stack style={formHeaderStyle}>
                  <Typography style={titleStyle}>{currentSection.name}</Typography>
                  <Stack>
                    {
                      currentSection.questions.map((question, questionIndex) => {
                        if (question.name != "") {
                          return (
                            <div style={style}>
                              <div style={boxStyle}>
                                <div key={question.name} style={alignLeftStyle}></div>                                  
                              </div>
                              <div>
                                <Typography style={inputStyle}>{question.question}</Typography>
                                {
                                  question.questionType == "shortAnswer" && 
                                  <>                                    
                                    {
                                      question.userAnswers.length > 0 ? 
                                        <TextField 
                                          id="outlined-basic" 
                                          variant="outlined" 
                                          style={inputStyle} 
                                          value={question.userAnswers[0].answer} 
                                          onChange={(e) => handleInputChange(currentSection.uuid, question.id, e.target.value)}
                                        /> 
                                      : 
                                        <TextField 
                                          id="outlined-basic" 
                                          variant="outlined" 
                                          style={inputStyle} 
                                          onChange={(e) => handleInputChange(currentSection.uuid, question.id, e.target.value)}
                                        />
                                    }
                                    
                                  </>
                                }
                                {
                                  question.questionType == "paragraph" && 
                                  <TextareaAutosize 
                                    style={textAreaStyle} 
                                    minRows={5} 
                                    placeholder={question.question} 
                                    onChange={(e) => handleInputChange(currentSection.uuid, question.id, e.target.value)}
                                  />
                                }
                                {
                                  question.questionType == "singleChoice" && 
                                  <div>
                                    <div style={optionsStyle}>
                                      {
                                        question.answers.map((answer) => 
                                          <div style={boxStyle}>
                                            <div style={alignLeftStyle}>
                                              {
                                                question.userAnswers[0].answer == answer.answer ?
                                                  <input 
                                                    type="radio" 
                                                    name={`answer-${questionIndex}`} 
                                                    key={`answer-${questionIndex}`} 
                                                    value={answer.answer} 
                                                    checked={question.userAnswers[0]?.answer === answer.answer} 
                                                    onChange={() => handleAnswerChange(currentSection.uuid, question.id, answer.answer)} 
                                                  />
                                                :
                                                  <input 
                                                    type="radio" 
                                                    name={`answer-${questionIndex}`} 
                                                    key={`answer-${questionIndex}`} 
                                                    value={answer.answer} 
                                                    checked={question.userAnswers[0]?.answer === answer.answer} 
                                                    onChange={() => handleAnswerChange(currentSection.uuid, question.id, answer.answer)} 
                                                  />
                                              }
                                              <label style={labelStyle}>{answer.answer}</label>
                                            </div>
                                          </div>
                                      )}
                                    </div>
                                  </div>
                                }
                                {
                                  question.questionType == "multipleChoice" && 
                                  <div>
                                    <div style={optionsStyle}>
                                      {
                                        question.answers.map((answer) => 
                                          <div>
                                            <input 
                                              type="checkbox" 
                                              name={question.name} 
                                              key={answer.answer} 
                                              value={answer.answer} 
                                            />
                                            <label style={labelStyle}>{answer.answer}</label>
                                          </div>
                                        )
                                      }
                                    </div>
                                  </div>
                                }
                                {
                                  question.questionType == "file" && 
                                  <div>
                                    <TextField type="file" />
                                  </div>
                                }
                                {
                                  question.questionType == "date" && 
                                  <div>
                                    <TextField 
                                      type="date" 
                                      id="date" 
                                      style={textFieldStyle} 
                                      onChange={(e) => handleInputChange(currentSection.uuid, question.id, e.target.value)}
                                    />
                                  </div>
                                }
                              </div>
                            </div>
                          );
                        }
                      })
                    }
                  </Stack>
                </Stack>
              </div>
            )
          }
          <Stack direction="row" spacing={2} sx={{ mt: 2, alignItems: 'center' }}>
            <Stack direction="row" spacing={2} sx={{ flexGrow: 1 }}>
              <Button 
                onClick={handleBack} 
                variant="outlined" 
                disabled={currentSectionIndex === 0}
              >
                Back
              </Button>
              <Button 
                onClick={handleNext} 
                variant="outlined" 
                disabled={currentSectionIndex === (application ? application.sections.length - 1 : -1)}
              >
                Next
              </Button>
              <Typography style={pageStyle}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Typography variant="body2">Page {currentSectionIndex + 1} of {(application ? application.sections.length : 0)}</Typography>
                  <div style={progressContainer}>
                    <div style={{ ...progressBar, width: `${progressPercentage}%` }}></div>
                  </div>
                </Box>
              </Typography>
            </Stack>
            <Box sx={{ marginLeft: 'auto' }}>
              <Button 
                onClick={() => saveAnswers()} 
                startIcon={<DoneIcon />} 
                variant="contained" 
                sx={{
                  mt: 2, 
                  backgroundColor: '#003D6E',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#005F8C',
                  },
                }}
              >
                Save
              </Button>
            </Box>
          </Stack>
        </Container>
      </Box>
    </>
  );
}

export default Application;