import { useEffect, useState } from 'react';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Sidenav from '../../components/Sidenav';
import { useNavigate } from 'react-router-dom';
import { ApiService } from '../../services/api.service';
import { ApplicationsTable } from '../../sections/applications';

const apiService = new ApiService();

const Applications = () => {
  const navigate = useNavigate();
  
  const now = new Date();

  const [data, setData] = useState([]);
  const [updateApplications, setUpdateApplications] = useState<number | undefined>(0);
  
  const [application, setApplication] = useState({
    name: "",
    description: ""
  });

  const fetchData = async () => {
    try {
      const response = await apiService.get("/applications");
      
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [updateApplications]);

  return (
    <>
      <Sidenav />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 10, 
          bgcolor: '#F0F1F5'
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={4}
            >
              <Stack spacing={1}>
                <Typography variant="h4">
                  Applications
                </Typography>
                <Stack
                  alignItems="center"
                  direction="row"
                  spacing={1}
                >
                </Stack>
              </Stack>
            </Stack>
            <ApplicationsTable
              count={data.length}
              items={data}
            />
          </Stack>
        </Container>
      </Box>
    </>
  );
};

Applications.getLayout = (page: any) => (
  //<DashboardLayout>
    {page}
  //</DashboardLayout>
);

export default Applications;