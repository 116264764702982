import { useEffect, useState } from 'react';
import { Box, Button, Container, FormControl, Input, InputLabel, MenuItem, Modal, Select, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Sidenav from '../../components/Sidenav';
import { ApiService } from '../../services/api.service';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { PlansTable } from '../../sections/plans';

interface Plan {
  customerName: string;
  customerEmail: string;
  product: string;
  status: string;
  createdAt: string;
  application: Application;
}

interface Customer {
  id: number;
  email: string;
}

interface Product {
  id: number;
  name: string;
}

interface Form {
  id: number;
  name: string;
}

interface Application {
  id: number;
}

const apiService = new ApiService();

const Plans = () => {
  const now = new Date();

  const [data, setData] = useState<Plan[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [updateUsers, setUpdateUsers] = useState<number | undefined>(0);
  const [forms, setForms] = useState<Form[]>([]);

  const [plan, setPlan] = useState({
    customerId: 0,
    productId: 0,
    formId: 0
  });

  const navigate = useNavigate();

  const handleCustomerChange = (event: any) => {
    const value = event.target.value;
    
    setPlan({
      ...plan,
      [event.target.name]: value
    });
  };

  const handleProductChange = (event: any) => {
    const value = event.target.value;
    
    setPlan({
      ...plan,
      [event.target.name]: value
    });
  };

  const handleFormChange = (event: any) => {
    const value = event.target.value;
    
    setPlan({
      ...plan,
      [event.target.name]: value
    });
  };

  const fetchPlans = async () => {
    try {
      const response = await apiService.get("/plans");

      if (response?.status == 403) {
        navigate('/dashboard');
      }

      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await apiService.get("/users/active-customers");

      if (response?.status == 403) {
        navigate('/dashboard');
      }

      setCustomers(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await apiService.get("/products");

      if (response?.status == 403) {
        navigate('/dashboard');
      }

      setProducts(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchForms = async () => {
    try {
      const response = await apiService.get("/forms");

      if (response?.status == 403) {
        navigate('/dashboard');
      }
      
      setForms(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const createPlan = async () => {
    try {
      setUpdateUsers(0);

      const response = await apiService.post("/plans", plan);
      setUpdateUsers(response?.status);
      
      setPlan({
        customerId: 0,
        productId: 0,
        formId: 0
      });

      handleClose();
      fetchPlans();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchPlans();
    fetchCustomers();
    fetchProducts();
    fetchForms();
  }, [updateUsers]);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const notify = (message: string) => toast(message);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid #FAFAFA',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <>
      <Sidenav />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 10,
          bgcolor: '#F0F1F5'
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={4}
            >
              <Stack spacing={1}>
                <Typography variant="h4">
                  Plans
                </Typography>
                <Stack
                  alignItems="center"
                  direction="row"
                  spacing={1}
                >
                </Stack>
              </Stack>
              <div>
                <Button
                  onClick={handleOpen}
                  startIcon={<AddIcon />}
                  variant="contained"
                  sx={{
                    backgroundColor: '#003D6E',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#005F8C',
                    },
                  }}
                >
                  Add
                </Button>
              </div>
            </Stack>
            <PlansTable
              count={data.length}
              items={data}
              refreshData={fetchPlans}
            />
          </Stack>
        </Container>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style }}>
          <Typography 
            id="modal-modal-title" 
            variant="h6" 
            component="h2"
          >
            Plan
          </Typography>
          <Typography sx={{ mt: 4 }}>
            <FormControl fullWidth>
              <InputLabel id="customer-label">Customer</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="customerId"
                value={plan.customerId}
                label="Customer"
                onChange={handleCustomerChange}
                sx={{ 
                  marginBottom: '30px'
                }}
              >
                {customers.map((customer) => (
                  <MenuItem key={customer.id} value={customer.id}>
                    {customer.email}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Typography>
          <Typography sx={{ mt: 4 }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="product-label">Product</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="productId"
                value={plan.productId}
                label="Product"
                onChange={handleProductChange}
                sx={{ 
                  marginBottom: '30px'
                }}
              >
                {products.map((product) => (
                  <MenuItem key={product.id} value={product.id}>
                    {product.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Typography>
          <Typography sx={{ mt: 4 }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="product-label">Form</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="formId"
                value={plan.formId}
                label="Form"
                onChange={handleFormChange}
                sx={{ 
                  marginBottom: '30px'
                }}
              >
                {forms.map((form) => (
                  <MenuItem key={form.id} value={form.id}>
                    {form.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Typography>
          <Button 
            color="primary" 
            variant="outlined" 
            onClick={handleClose} 
            sx={{ 
              mt: 2, 
              marginRight: 1, 
              borderColor: '#005F8C', 
              color: '#005F8C', 
            }}
          >
            Cancel
          </Button>
          <Button 
            color="primary" 
            variant="contained" 
            onClick={createPlan} 
            sx={{ 
              mt: 2,
              backgroundColor: '#003D6E', '&:hover': { 
                backgroundColor: '#005F8C' 
              }
            }}
          >
            Save
          </Button>
        </Box>
      </Modal>
    </>
  );
};

Plans.getLayout = (page: any) => (
  //<DashboardLayout>
    {page}
  //</DashboardLayout>
);

export default Plans;