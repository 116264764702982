import { useEffect, useState } from 'react';
import { Box, Button, Container, IconButton, MenuItem, Modal, Select, SelectChangeEvent, Stack, TextareaAutosize, Typography } from '@mui/material';
import Sidenav from '../../components/Sidenav';
import { ApiService } from '../../services/api.service';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import avatar from '../../../src/assets/images/avatar.png';
import { ToastContainer, toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import SyncIcon from '@mui/icons-material/Sync';
import Swal from 'sweetalert2';
import { Autorenew } from '@mui/icons-material';
import { title } from 'process';

interface Plan {
  id: number;
  customerName: string;
  customerEmail: string;
  product: string;
  status: string;
  createdAt: string;
  planDetails: PlanDetail[];
}

interface PlanDetail {
  id: number;
  notes: string;
  user: string;
  avatar: string;
  createdAt: string;
}

const apiService = new ApiService();

const PlanDetails = () => {
  const [plan, setPlan] = useState<Plan>();
  const [open, setOpen] = useState(false);
  const [notes, setNotes] = useState('');
  const [existingNotes, setExistingNotes] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<number>(0);
  
  const navigate = useNavigate();

  const { planId } = useParams<{planId: string}>();

  const planStatusMap = {
    1: 'Forms sent',
    2: 'Meeting booked',
    3: 'Waiting for info',
    4: 'Ready to apply',
    5: 'Application in progress',
    6: 'Waiting for approval',
    7: 'Pendency underwriting',
    8: 'Waiting client acceptance',
    9: 'In issuing'
  };

  const notify = (message: string) => toast(message);

  const fetchPlanDetails = async () => {
    try {
      const response = await apiService.get(`/plans/${planId}`);

      if (response?.status == 403) {
        navigate('/dashboard');
      }

      setPlan(response?.data);
      setExistingNotes(response.data.planDetails.map((planDetail: PlanDetail) => planDetail.notes));
      setSelectedStatus(response?.data.status)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const addNote = async () => {
    console.log(plan);

    const body = {
      planId: plan?.id,
      notes: notes
    }

    try {
      const response = await apiService.post("/plan-details/", body);

      if (response?.data.success) {
        fetchPlanDetails();

        notify(response.data.message);
      } else {
        notify(response.data.message);
      }

      handleClose();
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const updateNote = async (id: number, index: number) => {
    const body = {
      notes: existingNotes[index]
    }

    try {
      const response = await apiService.patch("/plan-details/" + id, body);

      if (response?.data.success) {
        fetchPlanDetails();

        notify(response.data.message);
      } else {
        notify(response.data.message);
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const deleteNote = async (id: number) => {
    try {
      Swal.fire({
        title: "Are you sure you want to remove this note?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.value) {
          apiService.delete("/plan-details/" + id).then(response => {
            if (response?.data.success) {
              fetchPlanDetails();
  
              notify(response.data.message);
            } else {
              notify(response.data.message);
            }
          });
        }
      });
      
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const updatePlanStatus = async (status: number) => {
    const body = {
      status: status
    }

    try {
      const response = await apiService.patch("/plans/" + plan?.id, body);

      if (response?.data.success) {
        fetchPlanDetails();

        notify(response.data.message);
      } else {
        notify(response.data.message);
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  useEffect(() => {
    fetchPlanDetails();
  }, []);

  const handleExistingNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => {
    const updatedNotes = [...existingNotes];
    updatedNotes[index] = event.target.value;
    setExistingNotes(updatedNotes);
  };

  const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNotes('');
  };

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    const value = Number(event.target.value);
    setSelectedStatus(value);
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid #FAFAFA',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const formHeaderStyle = {
    background: '#fff',
    borderTop: '6px solid #003D6E',
    borderRadius: '3px',
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)',
    marginBottom: '20px',
    padding: '30px'
  }

  const alignRightStyle = {
    marginRight: '20px',
    alignItems: 'center',
    marginLeft: '10px',
    paddingTop: '1px'
  }

  const selectStyle = {
    width: '280px',
    height: '36px',
    color: '#495057',
    verticalAlign: 'middle',
    background: '#fff',
    borderRadius: '3px',
    marginBottom: '5px'
  }

  const titleStyle = {
    marginBottom: '20px',
    marginLeft: '10px'
  }

  return (
    <>
      <Sidenav />
      <ToastContainer />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 10,
          bgcolor: '#F0F1F5'
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={4}
            >
              <Stack spacing={1}>
                <Typography variant="h6">
                  Plan details
                </Typography>
                <Stack
                  alignItems="center"
                  direction="row"
                  spacing={1}
                >
                </Stack>
              </Stack>
              <div>
                <Button 
                  color="primary" 
                  variant="outlined" 
                  onClick={() => navigate("/plans")} 
                  sx={{ 
                    mt: 2, 
                    marginRight: 1, 
                    marginTop: '0px', 
                    borderColor: '#005F8C', 
                    color: '#005F8C', 
                  }}
                >
                  Back
                </Button>
                <Button
                  onClick={handleOpen}
                  startIcon={<AddIcon />}
                  variant="contained"
                  sx={{
                    backgroundColor: '#003D6E',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#005F8C',
                    },
                  }}
                >
                  Add
                </Button>
              </div>
            </Stack>
            <Stack style={formHeaderStyle}>
              <Typography 
                variant="body1"
                style={titleStyle}
              >
                Current status
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Stack style={alignRightStyle}>
                  <Select
                    labelId="plan-status-label"
                    id="plan-status-select"
                    value={selectedStatus.toString()}
                    style={selectStyle}
                    onChange={handleStatusChange}
                  >
                    {Object.entries(planStatusMap).map(([value, label]) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack >
                <Button
                  onClick={() => updatePlanStatus(selectedStatus)}
                  startIcon={<Autorenew />}
                  variant="contained"
                  sx={{
                    backgroundColor: '#003D6E',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#005F8C',
                    },
                  }}
                >
                  Update status
                </Button>
              </Stack>
            </Stack>
            {
              plan?.planDetails.map((planDetail, index) => (
                <Stack style={formHeaderStyle}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <img 
                      src={(planDetail.avatar) ? planDetail.avatar : avatar} 
                      style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%'
                      }} 
                      alt="Avatar"
                    />
                    <Stack spacing={0}>
                      <Typography variant="body1">
                        { planDetail.user }
                      </Typography>
                      <Typography variant="body2">
                        { new Date(planDetail.createdAt).toDateString() }
                      </Typography>
                    </Stack>
                  </Stack>
                  <TextareaAutosize 
                    name="description" 
                    value={existingNotes[index]} 
                    style={{
                      border: 'none',
                      outline: 'none',
                      resize: 'none',
                      width: '100%',
                      padding: '0.375rem 1.75rem 0.375rem 0.75rem',
                      fontSize: '1rem',
                      fontFamily: 'Roboto',
                      borderRadius: '3px',
                      marginTop: '15px'
                    }}
                    minRows={5} 
                    onChange={(event) => handleExistingNotesChange(event, index)} 
                  />
                  <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={() => updateNote(planDetail.id, index)}>
                      <SyncIcon />
                    </IconButton>
                    <IconButton onClick={() => deleteNote(planDetail.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                </Stack>
              ))
            }            
          </Stack>
        </Container>
      </Box>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={{ ...style }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add notes
          </Typography>
          <Typography sx={{ mt: 4 }}>
            <TextareaAutosize 
              name="notes" 
              value={notes} 
              style={{
                border: '1px solid #333',
                outline: 'none',
                resize: 'none',
                width: '100%',
                padding: '0.375rem 1.75rem 0.375rem 0.75rem',
                fontSize: '1rem',
                fontFamily: 'Roboto',
                borderRadius: '3px',
              }}
              minRows={5} 
              onChange={handleNotesChange} 
            />
          </Typography>
          <Button 
            color="primary" 
            variant="outlined" 
            onClick={handleClose} 
            sx={{ 
              mt: 2, 
              marginRight: 1, 
              borderColor: '#005F8C', 
              color: '#005F8C', 
            }}
          >
            Cancel
          </Button>
          <Button 
            color="primary" 
            variant="contained" 
            onClick={addNote} 
            sx={{ 
              mt: 2,
              backgroundColor: '#003D6E', '&:hover': { 
                backgroundColor: '#005F8C' 
              }
            }}
          >
            Save
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default PlanDetails;