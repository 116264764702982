import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button, 
  Card,
  IconButton, 
  Input, 
  Modal, 
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import { ApiService } from '../services/api.service';

const apiService = new ApiService();

export const CustomersTable = (props: any) => {
  const {
    count = 0,
    items = [],
    onPageChange = () => {},
    onRowsPerPageChange = () => {},
    page = 0,
    rowsPerPage = 10,
    refreshData
  } = props;

  const role = localStorage.getItem("role");

  const [customer, setCustomer] = useState({
    id: 0,
    firstName: "",
    lastName: "",
    email: ""
  });

  const handleChange = (event: any) => {
    const value = event.target.value;
    
    setCustomer({
      ...customer,
      [event.target.name]: value
    });
  };

  const [open, setOpen] = useState(false);

  const handleOpen = (customer: any) => {
    setCustomer(customer);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateCustomer = async (id: number) => {
    const body = {
      "firstName": customer.firstName,
      "lastName": customer.lastName,
      "email": customer.email,
    }

    try {
      const response = await apiService.patch("/users/" + id, body);
      
      setCustomer({
        id: 0,
        firstName: "",
        lastName: "",
        email: ""
      });

      handleClose();
      
      if (refreshData) {
        refreshData();
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const deleteCustomer = async (id: number) => {
    Swal.fire({
      title: "Are you sure you want to remove this customer?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        apiService.delete("/users/" + id).then(response => {
          if (response?.status == 200) {
            refreshData();
          }
        });
      }
    });
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid #FAFAFA',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <Card>
      <Box sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Name</b>
              </TableCell>
              <TableCell>
                <b>Email</b>
              </TableCell>
              <TableCell>
                <b>Created at</b>
              </TableCell>
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((customer: any) => {
              return (
                <TableRow
                  hover
                  key={customer.id}
                >
                  <TableCell>
                    <Stack
                      alignItems="center"
                      direction="row"
                      spacing={2}
                    >                        
                      <Typography variant="subtitle2">
                        {customer.firstName + ' ' + customer.lastName}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>
                    {customer.email}
                  </TableCell>
                  <TableCell>
                    {customer.createdAt}
                  </TableCell>
                  <TableCell>
                    <IconButton>
                      <EditIcon onClick={() => handleOpen(customer)} />
                    </IconButton>
                    { role == 'Admin' && (
                      <IconButton onClick={() => deleteCustomer(customer.id)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        component="div"
        count={count}
        onPageChange={(event, newPage) => {
          onPageChange(newPage + 1);
        }}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page - 1}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style }}>
          <Typography 
            id="modal-modal-title" 
            variant="h6" 
            component="h2"
          >
            Customer
          </Typography>
          <Typography sx={{ mt: 4 }}>
            <Input 
              name="firstName" 
              value={customer.firstName} 
              fullWidth={true} 
              onChange={handleChange} 
            />
          </Typography>
          <Typography sx={{ mt: 4 }}>
            <Input 
              name="lastName" 
              value={customer.lastName} 
              fullWidth={true} 
              onChange={handleChange} 
            />
          </Typography>
          <Typography sx={{ mt: 4 }}>
            <Input 
              name="email" 
              value={customer.email} 
              fullWidth={true} 
              onChange={handleChange} 
              sx={{ 
                marginBottom: '30px'
              }}
            />
          </Typography>
          <Button 
            color="primary" 
            variant="outlined" 
            onClick={handleClose} 
            sx={{ 
              mt: 2, 
              marginRight: 1, 
              borderColor: '#005F8C', 
              color: '#005F8C', 
            }}
          >
            Cancel
          </Button>
          <Button 
            color="primary" 
            variant="contained" 
            onClick={() => updateCustomer(customer.id)} 
            sx={{ 
              mt: 2,
              backgroundColor: '#003D6E',
              color: 'white',
              '&:hover': {
                backgroundColor: '#005F8C',
              },
            }}
          >
            Save
          </Button>
        </Box>
      </Modal>
    </Card>
  );
};

CustomersTable.propTypes = {
  count: PropTypes.number,
  items: PropTypes.array,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  refreshData: PropTypes.func
};